// header styles
.main-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  img {
    display: block;
    width: 100%;
  }

  .header-top {
    position: relative;
  }

  .header-trigger {
    position: absolute;
    z-index: 3;
    width: 8.3vw;
    height: 47px;
    top: 70.8%;
    left: 69.6%;
    cursor: pointer;
  }
}

.subnav {
  position: relative;
  top: -12px;
  display: none;
  opacity: 0;
  transition: opacity .6s $timing;
  &.active {
    opacity: 1;
  }
}

.subnav-link {
  position: absolute;
  display: block;
  width: 328px;
  height: 246px;
  top: 110px;
  left: 1337px;
}
.close-subnav {
  position: absolute;
  cursor: pointer;
  top: 22px;
  right: 10px;
  width: 29px;
  height: 29px;
  z-index: 2;
}