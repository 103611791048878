.jumbo {
  width: 100%;
  height: 80vh;
  position: relative;
  overflow: hidden;
  transition: opacity 1s ease .3s;

  .content {
    position: absolute;
    z-index: 2;
    display: inline-block;
    top: 55.1%;
    left: 53.3%;
    h1 {
      color: #fff;
      font-size: 90px;
      line-height: 1.2;
      font-family: 'daimler_cacproregular', sans-serif;
      font-weight: 400;
      margin: 0;
    }
    span {
      font-family: 'daimler_caproregular', sans-serif;
      font-size: 23px;
      color: #fff;
      font-weight: 400;
      display: block;
    }
  }

  .scroll-down {
    position: absolute;
    left: 50%;
    margin-left: -25px;
    bottom: 35px;
    z-index: 2;
    animation: bounce 3s infinite;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.is-loaded .jumbo {
  opacity: 1 !important;
}