// sticky styles
.sticky-nav {
  position: fixed;
  right: 0;
  top: 40%;
  z-index: 9;
  transform: translate3d(100%,0,0);
  transition: transform .65s $timing 1.5s;
  .sticky-button {
    background: #030303;
    cursor: pointer;
    padding: 15px 18px;
    max-width: 67px;
    float: right;
  }
  .line {
    width: 31px;
    height: 3px;
    background-color: #fff;
    display: block;
    margin: 7px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .sticky-content {
    opacity: 0;
    clear: both;
    transform: translate3d(100%,0,0);
    transition: transform .65s $timing, opacity .65s $timing;
  }

  &.active {
    .sticky-content {
      opacity: 1;
      transform: translate3d(0,0,0);
    }
    .line:nth-child(2){
      opacity: 0;
    }

    .line:nth-child(1){
      transform: translateY(10px) rotate(45deg);
    }

    .line:nth-child(3){
      transform: translateY(-10px) rotate(-45deg);
    }
  }
}

.is-loaded .sticky-nav {
  transform: translate3d(0,0,0);
}