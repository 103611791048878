html {
  font-family: sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre, code {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  -webkit-text-decoration-skip: objects;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  text-transform: none;
}

button, input {
  overflow: visible;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

textarea {
  overflow: auto;
}

.custom-anim, .c-anim {
  opacity: 0;
}

.alpha-anim {
  transition: opacity 1.4s ease-out;
}

.alpha-anim.start-animation {
  opacity: 1;
}

.anim-lr {
  transform: translate(-10px, 0);
  transition: transform .4s ease-out,opacity .8s ease-out;
  backface-visibility: hidden;
}

.anim-lr.start-animation {
  opacity: 1;
  transform: translate(0, 0);
}

.anim-llr {
  transform: translate(-60px, 0);
  transition: transform .7s ease-out,opacity .8s ease-out;
  backface-visibility: hidden;
}

.anim-llr.start-animation {
  opacity: 1;
  transform: translate(0, 0);
}

.anim-ttb {
  transform: translate(0, -10px);
  transition: transform .4s ease-out,opacity .8s ease-out;
  backface-visibility: hidden;
}

.anim-ttb.start-animation {
  opacity: 1;
  transform: translate(0, 0);
}

.anim-btt {
  transform: translate(0, 20px);
  transition: transform .4s ease-out,opacity .8s ease-out;
  backface-visibility: hidden;
}

.anim-btt.start-animation {
  opacity: 1;
  transform: translate(0, 0);
}

.anim-rl {
  transform: translate(10px, 0);
  transition: transform .4s ease-out,opacity .8s ease-out;
  backface-visibility: hidden;
}

.anim-rl.start-animation {
  opacity: 1;
  transform: translate(0, 0);
}

.anim-rll {
  transform: translate(60px, 0);
  transition: transform .7s ease-out,opacity .8s ease-out;
  backface-visibility: hidden;
}

.anim-rll.start-animation {
  opacity: 1;
  transform: translate(0, 0);
}

@font-face {
  font-family: 'daimler_cacproregular';
  src: url("../fonts/daimlercacpro-regular.woff2") format("woff2"), url("../fonts/daimlercacpro-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'daimler_caproregular';
  src: url("../fonts/daimlercapro-regular.woff2") format("woff2"), url("../fonts/daimlercapro-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'daimler_csprodemi';
  src: url("../fonts/daimlercspro-demi.woff2") format("woff2"), url("../fonts/daimlercspro-demi.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'daimler_csprolight';
  src: url("../fonts/daimlercspro-light.woff2") format("woff2"), url("../fonts/daimlercspro-light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'daimler_csproregular';
  src: url("../fonts/daimlercspro-regular.woff2") format("woff2"), url("../fonts/daimlercspro-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html, body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.wrap {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding-top: 165px;
}

img {
  height: auto;
}

.spacer {
  width: 100%;
  height: 300px;
}

.tal-right {
  text-align: right;
}

.tal-left {
  text-align: left;
}

.jumbo {
  width: 100%;
  height: 80vh;
  position: relative;
  overflow: hidden;
  transition: opacity 1s ease .3s;
}

.jumbo .content {
  position: absolute;
  z-index: 2;
  display: inline-block;
  top: 55.1%;
  left: 53.3%;
}

.jumbo .content h1 {
  color: #fff;
  font-size: 90px;
  line-height: 1.2;
  font-family: 'daimler_cacproregular', sans-serif;
  font-weight: 400;
  margin: 0;
}

.jumbo .content span {
  font-family: 'daimler_caproregular', sans-serif;
  font-size: 23px;
  color: #fff;
  font-weight: 400;
  display: block;
}

.jumbo .scroll-down {
  position: absolute;
  left: 50%;
  margin-left: -25px;
  bottom: 35px;
  z-index: 2;
  animation: bounce 3s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.is-loaded .jumbo {
  opacity: 1 !important;
}

section {
  width: 100%;
  height: calc(100vh - 165px);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  overflow: hidden;
  position: relative;
}

section > div {
  position: relative;
  overflow: hidden;
}

section .w-60 {
  flex: 0 1 66vw;
  align-self: auto;
  width: 66vw;
}

section .w-40 {
  flex: 0 1 34vw;
  align-self: auto;
  width: 34vw;
}

section .w-50 {
  flex: 0 1 50vw;
  align-self: auto;
  width: 50vw;
}

section .w-100 {
  flex: 0 1 100vw;
  align-self: auto;
  width: 100vw;
}

section .w-33 {
  flex: 0 1 33vw;
  align-self: auto;
  width: 33vw;
}

section .bg-sizer {
  position: relative;
  overflow: hidden;
}

section .bg-sizer > img {
  visibility: hidden;
  display: block;
}

section .custom-headline {
  font-family: 'daimler_cacproregular', sans-serif;
  font-size: 60px;
  line-height: 1.05;
  color: #030303;
  font-weight: 400;
  margin: 0 0 80px 132px;
}

section .bg-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

section .bg-wrap.fixed {
  background: no-repeat center center;
  background-attachment: fixed;
}

section .bg-wrap img {
  display: block;
}

section.limited {
  justify-content: space-between;
  align-content: space-between;
  overflow: hidden;
  position: relative;
  height: auto;
  padding: 45px 0 160px;
  max-width: 1600px;
  margin: 0 auto;
}

.title-container {
  position: absolute;
  z-index: 3;
  font-family: 'daimler_cacproregular', sans-serif;
  font-size: 60px;
  line-height: 1.05;
  color: #030303;
  font-weight: 400;
}

.arrow-right {
  position: absolute;
  top: 44%;
  left: 63.5%;
  z-index: 3;
}

.product {
  position: absolute;
  z-index: 2;
}

.text-content {
  padding: 0 145px 0 107px;
  color: #333;
  line-height: 1.5;
}

.text-content h3 {
  font-size: 16px;
  text-transform: uppercase;
  font-family: 'daimler_csprodemi', sans-serif;
  font-weight: 400;
}

.text-content p {
  font-family: 'daimler_csproregular', sans-serif;
  font-size: 16px;
}

.text-content.absolute {
  position: absolute;
}

article {
  margin-bottom: 30px;
  color: #333;
  line-height: 1.5;
  cursor: pointer;
}

article p {
  font-family: 'daimler_csproregular', sans-serif;
  font-size: 16px;
  transition: color .5s ease;
}

article > p > span {
  font-family: 'daimler_csprodemi', sans-serif;
  font-weight: 400;
  color: #333 !important;
}

article .shopnow {
  display: inline-block;
  text-decoration: none;
  vertical-align: middle;
}

article .shopnow i {
  width: 21px;
  height: 10px;
  background: url("../images/arrow-small.png") no-repeat center center;
  display: inline-block;
  margin: 0 10px;
  transform: translateX(0px);
  transition: background 0.6s cubic-bezier(0.5, 0.3, 0.2, 1), transform 0.6s cubic-bezier(0.5, 0.3, 0.2, 1);
}

article .shopnow span {
  opacity: 0;
  transform: translateX(-4px);
  transition: opacity 0.6s cubic-bezier(0.5, 0.3, 0.2, 1), transform 0.6s cubic-bezier(0.5, 0.3, 0.2, 1);
  vertical-align: middle;
  font-family: 'daimler_csprodemi', sans-serif;
  color: #009cde;
  line-height: 1;
  position: relative;
  top: -2px;
}

article:hover p {
  color: #a8a8a8;
}

article:hover .shopnow i {
  background-image: url("../images/arrow-small-blue.png");
  transform: translateX(4px);
}

article:hover .shopnow span {
  opacity: 1;
  transform: translateX(0);
}

.video-container {
  position: absolute;
  top: 165px;
  left: 230px;
  padding-left: 30px;
}

.video-container .text-bt {
  font-family: 'daimler_cacproregular', sans-serif;
  font-weight: 400;
  color: #333;
  font-size: 18px;
  transform-origin: left;
  transform: rotate(-90deg) translate(-57%, -15px);
}

.small-headline {
  font-family: 'daimler_cacproregular', sans-serif;
  font-weight: 400;
  color: #333;
  line-height: 1.52;
  font-size: 23px;
}

.title-center {
  margin: 100px auto 20px;
  text-align: center;
}

.hover-container {
  width: 100%;
  max-width: 1475px;
  margin: 70px auto 150px;
}

.hover-container .hover-wrap {
  width: 1370px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: space-between;
  align-items: center;
  position: relative;
}

.hover-container .hover-wrap:before {
  content: '';
  background: url("../images/arrow-slider-left.jpg") no-repeat center center;
  width: 16px;
  height: 30px;
  position: absolute;
  top: 95px;
  left: -35px;
  display: block;
  cursor: pointer;
}

.hover-container .hover-wrap:after {
  content: '';
  background: url("../images/arrow-slider-right.jpg") no-repeat center center;
  width: 16px;
  height: 30px;
  position: absolute;
  top: 95px;
  right: -35px;
  display: block;
  cursor: pointer;
}

.hover-container .item-bg {
  width: 322px;
  height: 221px;
  background: #000;
  position: relative;
  overflow: hidden;
}

.hover-container .item-bg img {
  display: block;
  height: 100%;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) scale3d(1.01, 1.01, 1.01);
  opacity: 1;
  backface-visibility: hidden;
  transition: transform 0.6s cubic-bezier(0.5, 0.3, 0.2, 1), opacity 0.6s cubic-bezier(0.5, 0.3, 0.2, 1);
}

.hover-container .item-bg .button {
  display: block;
  color: #009cde;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'daimler_cacproregular', sans-serif;
  font-size: 24px;
  padding: 18px 22px;
  border: 1px solid #009cde;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 220px;
  transform: translate3d(-50%, -50%, 0) scale3d(1.05, 1.05, 1.05);
  backface-visibility: hidden;
  opacity: 0;
  transition: opacity 0.6s cubic-bezier(0.5, 0.3, 0.2, 1), transform 0.6s cubic-bezier(0.5, 0.3, 0.2, 1), background 0.4s ease;
  text-align: center;
}

.hover-container .item-bg .button:hover {
  background: rgba(0, 0, 0, 0.4);
}

.hover-container span {
  display: block;
}

.hover-container span.title {
  font-family: 'daimler_cacproregular', sans-serif;
  font-size: 30px;
  color: #333;
  font-weight: 400;
  margin: 20px 2px 4px;
}

.hover-container span.subline {
  font-family: 'daimler_csproregular', sans-serif;
  font-size: 17px;
  font-weight: 400;
  color: #333;
}

.hover-container .item:hover .item-bg img {
  transform: translate3d(-50%, -50%, 0) scale3d(1.05, 1.05, 1.05);
  opacity: .3;
}

.hover-container .item:hover .button {
  opacity: 1;
  transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
}

.main-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
}

.main-header img {
  display: block;
  width: 100%;
}

.main-header .header-top {
  position: relative;
}

.main-header .header-trigger {
  position: absolute;
  z-index: 3;
  width: 8.3vw;
  height: 47px;
  top: 70.8%;
  left: 69.6%;
  cursor: pointer;
}

.subnav {
  position: relative;
  top: -12px;
  display: none;
  opacity: 0;
  transition: opacity 0.6s cubic-bezier(0.5, 0.3, 0.2, 1);
}

.subnav.active {
  opacity: 1;
}

.subnav-link {
  position: absolute;
  display: block;
  width: 328px;
  height: 246px;
  top: 110px;
  left: 1337px;
}

.close-subnav {
  position: absolute;
  cursor: pointer;
  top: 22px;
  right: 10px;
  width: 29px;
  height: 29px;
  z-index: 2;
}

.sticky-nav {
  position: fixed;
  right: 0;
  top: 40%;
  z-index: 9;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.65s cubic-bezier(0.5, 0.3, 0.2, 1) 1.5s;
}

.sticky-nav .sticky-button {
  background: #030303;
  cursor: pointer;
  padding: 15px 18px;
  max-width: 67px;
  float: right;
}

.sticky-nav .line {
  width: 31px;
  height: 3px;
  background-color: #fff;
  display: block;
  margin: 7px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.sticky-nav .sticky-content {
  opacity: 0;
  clear: both;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.65s cubic-bezier(0.5, 0.3, 0.2, 1), opacity 0.65s cubic-bezier(0.5, 0.3, 0.2, 1);
}

.sticky-nav.active .sticky-content {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.sticky-nav.active .line:nth-child(2) {
  opacity: 0;
}

.sticky-nav.active .line:nth-child(1) {
  transform: translateY(10px) rotate(45deg);
}

.sticky-nav.active .line:nth-child(3) {
  transform: translateY(-10px) rotate(-45deg);
}

.is-loaded .sticky-nav {
  transform: translate3d(0, 0, 0);
}
