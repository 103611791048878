@font-face {
  font-family: 'daimler_cacproregular';
  src: url('../fonts/daimlercacpro-regular.woff2') format('woff2'),
  url('../fonts/daimlercacpro-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'daimler_caproregular';
  src: url('../fonts/daimlercapro-regular.woff2') format('woff2'),
  url('../fonts/daimlercapro-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'daimler_csprodemi';
  src: url('../fonts/daimlercspro-demi.woff2') format('woff2'),
  url('../fonts/daimlercspro-demi.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'daimler_csprolight';
  src: url('../fonts/daimlercspro-light.woff2') format('woff2'),
  url('../fonts/daimlercspro-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'daimler_csproregular';
  src: url('../fonts/daimlercspro-regular.woff2') format('woff2'),
  url('../fonts/daimlercspro-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
