html, body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.wrap {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding-top: 165px;
}

img {
  height: auto;

}

.spacer {
  width: 100%;
  height: 300px
}
