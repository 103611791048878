section {
  width: 100%;
  height: calc(100vh - 165px);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  overflow: hidden;
  position: relative;
  > div {
    position: relative;
    overflow: hidden;
  }

  .w-60 {
    flex: 0 1 66vw;
    align-self: auto;
    width: 66vw;
  }
  .w-40 {
    flex: 0 1 34vw;
    align-self: auto;
    width: 34vw;
  }

  .w-50 {
    flex: 0 1 50vw;
    align-self: auto;
    width: 50vw;
  }

  .w-100 {
    flex: 0 1 100vw;
    align-self: auto;
    width: 100vw;
  }
  .w-33 {
    flex: 0 1 33vw;
    align-self: auto;
    width: 33vw;
  }

  .bg-sizer {
    position: relative;
    overflow: hidden;
    > img {
      visibility: hidden;
      display: block;
    }
  }

  .custom-headline {
    font-family: 'daimler_cacproregular', sans-serif;
    font-size: 60px;
    line-height: 1.05;
    color: #030303;
    font-weight: 400;
    margin: 0 0 80px 132px;
  }

  .bg-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &.fixed {
      background: no-repeat center center;
      background-attachment: fixed;
    }
    img {
      display: block;
    }
  }

  &.limited {
    justify-content: space-between;
    align-content: space-between;
    overflow: hidden;
    position: relative;
    height: auto;
    padding: 45px 0 160px;
    max-width: 1600px;
    margin: 0 auto;
  }
}

.title-container {
  position: absolute;
  z-index: 3;
  font-family: 'daimler_cacproregular', sans-serif;
  font-size: 60px;
  line-height: 1.05;
  color: #030303;
  font-weight: 400;
}

.arrow-right {
  position: absolute;
  top: 44%;
  left: 63.5%;
  z-index: 3;
}

.product {
  position: absolute;
  z-index: 2;
}

.text-content {
  padding: 0 145px 0 107px;
  color: #333;
  line-height: 1.5;
  h3 {
    font-size: 16px;
    text-transform: uppercase;
    font-family: 'daimler_csprodemi', sans-serif;
    font-weight: 400;
  }
  p {
    font-family: 'daimler_csproregular', sans-serif;
    font-size: 16px;
  }
  &.absolute {
    position: absolute;
  }
}

article {
  margin-bottom: 30px;
  color: #333;
  line-height: 1.5;
  cursor: pointer;
  p {
    font-family: 'daimler_csproregular', sans-serif;
    font-size: 16px;
    transition: color .5s ease;
  }
  > p > span {
    font-family: 'daimler_csprodemi', sans-serif;
    font-weight: 400;
    color: #333 !important;
  }
  .shopnow {
    display: inline-block;
    text-decoration: none;
    vertical-align: middle;
    i {
      width: 21px;
      height: 10px;
      background: url('../images/arrow-small.png') no-repeat center center;
      display: inline-block;
      margin: 0 10px;
      transform: translateX(0px);
      transition: background .6s $timing, transform .6s $timing;
    }
    span {
      opacity: 0;
      transform: translateX(-4px);
      transition: opacity .6s $timing, transform .6s $timing;
      vertical-align: middle;
      font-family: 'daimler_csprodemi', sans-serif;
      color: #009cde;
      line-height:1;
      position: relative;
      top: -2px;
    }
  }
  &:hover {
    p {
      color: #a8a8a8;
    }
    .shopnow {
      i {
        background-image: url('../images/arrow-small-blue.png');
        transform: translateX(4px);
      }
      span {
        opacity: 1;
        transform: translateX(0);
      }
    }

  }
}


.video-container {
  position: absolute;
  top: 165px;
  left: 230px;
  padding-left: 30px;
  .text-bt {
    font-family: 'daimler_cacproregular', sans-serif;
    font-weight: 400;
    color: #333;
    font-size: 18px;
    transform-origin: left;
    transform: rotate(-90deg) translate(-57%, -15px);
  }
}

.small-headline {
  font-family: 'daimler_cacproregular', sans-serif;
  font-weight: 400;
  color: #333;
  line-height:1.52;
  font-size: 23px;
}

.title-center {
  margin: 100px auto 20px;
  text-align: center;
}


.hover-container {
  width: 100%;
  max-width: 1475px;
  margin: 70px auto 150px;
  .hover-wrap {
    width: 1370px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: space-between;
    align-items: center;
    position: relative;
    &:before {
      content: '';
      background: url('../images/arrow-slider-left.jpg') no-repeat center center;
      width: 16px;
      height: 30px;
      position: absolute;
      top: 95px;
      left: -35px;
      display: block;
      cursor: pointer;
    }
    &:after {
      content: '';
      background: url('../images/arrow-slider-right.jpg') no-repeat center center;
      width: 16px;
      height: 30px;
      position: absolute;
      top: 95px;
      right: -35px;
      display: block;
      cursor: pointer;
    }
  }
  .item-bg {
    width: 322px;
    height: 221px;
    background: #000;
    position: relative;
    overflow: hidden;
    img {
      display: block;
      height: 100%;
      width: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%,-50%, 0) scale3d(1.01,1.01,1.01);
      opacity: 1;
      backface-visibility: hidden;
      transition: transform .6s $timing, opacity .6s $timing;
    }
    .button {
      display: block;
      color: #009cde;
      text-decoration: none;
      text-transform: uppercase;
      font-family: 'daimler_cacproregular', sans-serif;
      font-size: 24px;
      padding: 18px 22px;
      border: 1px solid #009cde;
      background: rgba(0,0,0,0);
      position: absolute;
      top: 50%;
      left: 50%;
      width: 220px;
      transform: translate3d(-50%,-50%, 0) scale3d(1.05,1.05,1.05);
      backface-visibility: hidden;
      opacity: 0;
      transition: opacity .6s $timing, transform .6s $timing, background .4s ease;
      text-align: center;
      &:hover {
        background: rgba(0,0,0,.4);
      }
    }
  }
  span {
    display: block;
    &.title {
      font-family: 'daimler_cacproregular', sans-serif;
      font-size: 30px;
      color: #333;
      font-weight: 400;
      margin: 20px 2px 4px;
    }
    &.subline {
      font-family: 'daimler_csproregular', sans-serif;
      font-size: 17px;
      font-weight: 400;
      color: #333;
    }
  }

  .item {
    &:hover {
      .item-bg img {
        transform: translate3d(-50%, -50%, 0) scale3d(1.05,1.05,1.05);
        opacity: .3;
      }
      .button {
        opacity: 1;
        transform: translate3d(-50%,-50%, 0) scale3d(1,1,1);
      }
    }
  }
}